import React from 'react';
import { Modal } from '@material-ui/core';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

const DeleteMessageModal = ({ show, onClose, deleteConfirmation, cardInfo }) => {
    const handleDeleteConfirmation = async () => {
        try {
            await deleteConfirmation(cardInfo);  // Asegura que la eliminación se complete antes de cerrar
            onClose();  // Cierra el modal tras eliminar la tarjeta
        } catch (error) {
            console.error("Error eliminando la tarjeta:", error);
        }
    }

    return (
        <Modal open={show} onClose={onClose}>
            <div className='bg-white  mx-auto rounded-lg px-5 md:pt-8 pt-4 pb-4  overflow-y-auto  w-4/5 md:w-2/5 min-h-min mt-28 flex flex-col'>
                <div className='flex justify-end mt-0 cursor-pointer' onClick={() => onClose()}>
                    <IoClose size={25} color="#002821"></IoClose>
                </div>
                <div className='border-b-2 border-sc-body  justify-center  flex'>
                    <p className='text text-sc-primary font-bold text-base ml-4 mb-0'>Confirmación de eliminación</p>
                </div>
                <p className='mt-2 mb-0 text-center text-sm'>
                    <strong>
                        ¿Deseas eliminar la tarjeta TCD **** {cardInfo ? cardInfo.card.last4 : ""}?
                    </strong>
                </p>
                <div className='flex justify-between mx-14 mt-4'>
                    <div className='border border-sc-secondary hover:bg-sc-secondary hover:text-sc-menu-bg rounded-full pl-2 pr-2 cursor-pointer' onClick={() => onClose()} children={"Cancelar"}></div>
                    <div className='border border-sc-secondary hover:bg-sc-secondary hover:text-sc-menu-bg rounded-full pl-2 pr-2 cursor-pointer' onClick={handleDeleteConfirmation} children={"Aceptar"}></div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteMessageModal;
